import blaze from "../assests/courses/blaze.png";
import ignite from "../assests/courses/Ignite.png";
import inferno from "../assests/courses/inferno.png";

const coursesData = [
  {
    id: 1,
    name: "Ignite",
    description: "For College Students",
    highlights: [
      "Lifetime Access",
      "Industry-Relevant Projects",
      "Live Classes",
      "Regular Assessments",
      "24x7 Doubt Solving",
      "1:1 Mentorship",
    ],
    image: blaze,
  },
  {
    id: 2,
    name: "Blaze",
    description: "For Working Professionals",
    highlights: [
      "Lifetime Access",
      "Industry-Relevant Projects",
      "Live Classes",
      "Regular Assessments",
      "24x7 Doubt Solving",
      "1:1 Mentorship",
    ],
    image: ignite, 
  },
  {
    id: 3,
    name: "Inferno",
    description: "For Advanced Learners and Professionals",
    highlights: [
      "Lifetime Access",
      "Industry-Relevant Projects",
      "Live Classes",
      "Regular Assessments",
      "24x7 Doubt Solving",
      "1:1 Mentorship",
    ],
    image: inferno, 
  },
];

export default coursesData;
